/* eslint-disable react-hooks/rules-of-hooks */
import {useMergedRefs} from 'quickstart/hooks/useMergedRefs'
import {
  cloneElement,
  ComponentPropsWithRef,
  CSSProperties,
  ElementType,
  HTMLAttributes,
  isValidElement,
  MutableRefObject,
  ReactElement,
  ReactNode,
  Ref,
  RefCallback,
} from 'react'

/**
 * Any object.
 */
type AnyObject = Record<string, any>

/**
 * Empty object.
 */
type EmptyObject = Record<keyof any, never>

/**
 * Extends React.CSSProperties to support custom properties starting with double dash.
 */
type CSSPropertiesX = CSSProperties & {
  [k: `--${string}`]: unknown
}

/**
 * Extends props to support data props and custom CSS props.
 */
type Customized<T extends {style?: CSSProperties}> = Omit<T, 'style'> & {
  style?: CSSPropertiesX | CSSProperties
  [k: `data-${string}`]: unknown
}

/**
 * Extends React.HTMLAttributes to support custom properties in style.
 */
type HTMLAttributesX<T> = Customized<HTMLAttributes<T>>

/**
 * Render prop type.
 * @template P Props
 * @example
 * const children: RenderProp = (props) => <div {...props} />;
 */
export type RenderProp<P = HTMLAttributesX<any> & {ref?: Ref<any>}> = (
  props: P,
) => ReactNode

/**
 * The `wrapElement` prop.
 */
export type WrapElement = (element: ReactElement) => ReactElement

/**
 * Custom props including the `render` prop.
 */
export interface Options {
  as?: RenderProp | ElementType
  wrapElement?: WrapElement
  /**
   * Allows the component to be rendered as a different HTML element or React
   * component. The value can be a React element or a function that takes in the
   * original component props and gives back a React element with the props
   * merged.
   */
  render?: RenderProp | ReactElement
}

/**
 * HTML props based on the element type, excluding custom props.
 * @template T The element type.
 * @template P Custom props.
 * @example
 * type ButtonHTMLProps = HTMLProps<"button", { custom?: boolean }>;
 */
export type HTMLProps<
  T extends ElementType,
  P extends AnyObject = EmptyObject,
> = Customized<Omit<ComponentPropsWithRef<T>, keyof P>>

/**
 * Props based on the element type, including custom props.
 * @template T The element type.
 * @template P Custom props.
 */
export type Props<
  T extends ElementType,
  P extends AnyObject = EmptyObject,
> = P & HTMLProps<T, P>

/**
 * A component hook that supports the `render` prop and returns HTML props based
 * on the element type.
 * @template T The element type.
 * @template P Custom props.
 * @example
 * type UseButton = Hook<"button", { custom?: boolean }>;
 */
export type Hook<T extends ElementType, P extends AnyObject = EmptyObject> = <
  ET extends ElementType = T,
>(
  props?: Props<ET, P>,
) => HTMLProps<ET, P>

/**
 * Sets both a function and object React ref.
 */
export function setRef<T>(
  ref: RefCallback<T> | MutableRefObject<T> | null | undefined,
  value: T,
) {
  if (typeof ref === 'function') {
    ref(value)
  } else if (ref) {
    ref.current = value
  }
}

/**
 * Checks if an element is a valid React element with a ref.
 */
export function isValidElementWithRef<P extends {ref?: Ref<any>}>(
  element: unknown,
): element is ReactElement<P> & {ref?: Ref<any>} {
  if (!element) return false
  if (!isValidElement<{ref?: Ref<any>}>(element)) return false
  if ('ref' in element.props) return true
  if ('ref' in element) return true
  return false
}

/**
 * Gets the ref property from a React element.
 */
export function getRefProperty(element: unknown) {
  if (!isValidElementWithRef(element)) return null
  const props = {...element.props}
  return props.ref || element.ref
}

/**
 * Checks whether `prop` is an own property of `obj` or not.
 */
export function hasOwnProperty<T extends AnyObject>(
  object: T,
  prop: keyof any,
): prop is keyof T {
  if (typeof Object.hasOwn === 'function') {
    return Object.hasOwn(object, prop)
  }
  return Object.prototype.hasOwnProperty.call(object, prop)
}

/**
 * Type guard to convert complex to const.
 */
const is = <T,>(a: unknown, b: T): a is T => a === b

/**
 * Merges two sets of props.
 */
export function mergeProps<T extends HTMLAttributesX<any>>(
  base: T,
  overrides: T,
) {
  const props = {...base}

  for (const key in overrides) {
    if (!hasOwnProperty(overrides, key)) continue

    if (is(key, 'className')) {
      props[key] = base[key] ? `${base[key]} ${overrides[key]}` : overrides[key]
      continue
    }

    if (is(key, 'style')) {
      props[key] =
        base[key] ? {...base[key], ...overrides[key]} : overrides[key]
      continue
    }

    const overrideValue = overrides[key]

    if (typeof overrideValue === 'function' && key.startsWith('on')) {
      const baseValue = base[key]
      if (typeof baseValue === 'function') {
        type EventKey = Extract<keyof HTMLAttributesX<any>, `on${string}`>
        props[key as EventKey] = (...args) => {
          overrideValue(...args)
          baseValue(...args)
        }
        continue
      }
    }

    props[key] = overrideValue
  }

  return props
}

/**
 * Creates a React element that supports the `render` and `wrapElement` props,
 * along with `as` for backward compatibility.
 */
export function createElement(
  Type: ElementType,
  props: Props<ElementType, Options>,
) {
  const {as: As, wrapElement, render, ...rest} = props
  const mergedRef = useMergedRefs([props.ref, getRefProperty(render)])

  let element: ReactElement

  if (isValidElement<any>(render)) {
    const renderProps = {...(render.props as any), ref: mergedRef}
    element = cloneElement(render, mergeProps(rest, renderProps))
  } else if (render) {
    element = render(rest) as ReactElement
  } else if (As) {
    element = <As {...rest} />
  } else {
    element = <Type {...rest} />
  }

  if (wrapElement) {
    return wrapElement(element)
  }

  return element
}

/**
 * Creates a component hook that accepts props and returns props so they can be
 * passed to a React element.
 */
export function createHook<
  T extends ElementType,
  P extends AnyObject = EmptyObject,
>(useProps: (props: Props<T, P>) => HTMLProps<T, P>) {
  const useRole = (props: Props<T, P> = {} as Props<T, P>) => {
    return useProps(props)
  }
  useRole.displayName = useProps.name
  return useRole as Hook<T, P>
}
