import {BookshelfBlockGlobalConfig} from 'quickstart/blocks/BookshelfBlock/meta'
import {useEffect, useState} from 'react'
import {
  ApiError,
  Checkout,
  Checkouts,
  FormErrors,
  api,
  isEmptyObj,
  logger,
} from 'tizra'
import {useApi} from './useApi'
import {useHack} from './useHack'

const log = logger('useCheckout')

export const FULFILLER_BUTTONS_CHECKOUT_NAME = '_fulfiller-method'
export const FULFILLER_BUTTONS_STEP_NAME = '_fulfiller-step'

export const classicMeta: Checkouts = {
  checkouts: [
    {
      displayName: 'Classic checkout',
      name: FULFILLER_BUTTONS_CHECKOUT_NAME,
    },
  ],
  defaultCheckout: FULFILLER_BUTTONS_CHECKOUT_NAME,
}

export const classicCheckout: Checkout = {
  ...classicMeta.checkouts[0],
  checkoutStatus: 'complete',
  cart: [],
  infoItems: [],
  steps: [
    {
      name: FULFILLER_BUTTONS_STEP_NAME,
      displayName: 'Payment',
      final: true,
      optional: false,
    },
  ],
  nonce: 'hmmm',
}

const resolveCheckoutName = (
  meta: Checkouts | undefined,
  checkoutName: string | undefined,
) => {
  return (
    !meta ? undefined
    : meta.checkouts.length === 1 ? meta.checkouts[0].name
    : checkoutName && meta.checkouts.some(cc => cc.name === checkoutName) ?
      checkoutName
    : meta.defaultCheckout
  )
}

export interface UseCheckoutProps {
  bookshelfConfig: BookshelfBlockGlobalConfig
  enabled?: boolean
}

interface DialogProps {
  title: string
  message: string
  button: string
  action: () => void
}

export interface UseCheckoutReturn extends Partial<Checkouts> {
  checkoutName?: string
  checkout?: Checkout
  setCheckoutName: (checkoutName: string) => void
  submit: (stepName: string, values: any) => Promise<FormErrors | undefined>
  loading: boolean
  dialog?: DialogProps
}

/**
 * React hook for calling the checkout API.
 */
export const useCheckout = ({
  bookshelfConfig,
  enabled = true,
}: UseCheckoutProps): UseCheckoutReturn => {
  const checkoutApiEnabled = useHack('checkoutApi')
  const {data: _meta, isLoading: checkoutsLoading} = useApi.checkouts(
    enabled && checkoutApiEnabled,
  )
  const meta =
    checkoutApiEnabled ? _meta
    : enabled ? classicMeta
    : undefined

  const [_checkoutName, setCheckoutName] = useState<string>()
  const checkoutName = resolveCheckoutName(meta, _checkoutName)

  // TODO: additional abstraction to update cart in react-query
  const {data: gotCheckout, isLoading: checkoutLoading} = useApi.getCheckout(
    enabled && checkoutApiEnabled && checkoutName && {checkoutName},
    // Force refetch every time checkoutName changes.
    {gcTime: 0, staleTime: 0},
  )
  const [_checkout, setCheckout] = useState<Checkout | undefined>(gotCheckout)
  const checkout =
    checkoutApiEnabled ?
      !gotCheckout ? undefined
      : _checkout?.name === checkoutName ? _checkout
      : gotCheckout
    : enabled ? classicCheckout
    : undefined

  const [dialog, setDialog] = useState<DialogProps | null>(null)

  // If cache is invalidated, forcing a reload of checkout, then clear the
  // dialog, too.
  useEffect(() => {
    setDialog(null)
    setCheckout(gotCheckout)
  }, [gotCheckout])

  const [submitting, setSubmitting] = useState<boolean>(false)

  const submit = async (stepName: string, values: any) => {
    if (!enabled)
      return {
        reason: 'enabled=false',
        message: 'Attempted submit with enabled=false',
      }
    if (!checkoutApiEnabled)
      return {
        reason: 'checkoutApiEnabled=false',
        message: 'Attempted submit with checkoutApiEnabled=false',
      }
    if (!checkoutName)
      return {
        reason: 'checkoutName=undefined',
        message: 'Attempted submit with undefined checkoutName',
      }
    if (!checkout)
      return {
        reason: 'checkout=undefined',
        message: 'Attempted submit with undefined checkout',
      }

    setSubmitting(true)

    // Whether to post step input or call the submit endpoint depends on whether
    // the step is marked final.
    const final = checkout.steps.find(step => step.name === stepName)?.final
    const apiPostOrSubmitPromise =
      final ?
        api.submitCheckout({checkoutName, nonce: checkout.nonce})
      : api.postCheckout({checkoutName, stepName, values})
    const {status, ...data} = await apiPostOrSubmitPromise.catch(e => {
      // Only here for 500, not 40x per entry in info.ts
      log.error(e)
      return {
        status: (e instanceof ApiError && e.status) || 0,
        reason: 'exception',
        message: `${e}`,
        errors: undefined,
      }
    })

    setSubmitting(false)

    if ('steps' in data) {
      setCheckout(data)
      return
    }

    // If the API returns permission denied, this means the session expired
    // between the time we rendered the form and now, so we need to reload the
    // page.
    if (status === 403) {
      setDialog({
        title: 'Whoops',
        message: 'Session expired. Page will reload.',
        action: () => window.location.reload(),
        button: 'Continue',
      })
      return
    }

    if (final || 'transaction' in data) {
      if ('transaction' in data) {
        const {transaction, url} = data
        if (transaction.status == 'CLOSED') {
          // We're all done and should redirect to the transaction view, but we
          // don't have that yet. Instead, redirect to the bookshelf with message.
          setDialog({
            title: 'Success!',
            message: 'Items have been added to your account.',
            action: () => window.location.assign(bookshelfConfig.url),
            button: 'Continue',
          })
          return
        }
        if (url) {
          // Entering PayPal sequence.
          window.location.assign(url)
          return
        }
      }
      setDialog({
        title: 'Whoops',
        message:
          ('message' in data && data.message) ||
          ('reason' in data && data.reason) ||
          ('transaction' in data &&
            `Unexpected transaction status: ${data.transaction.status}.`) ||
          `Unexpected response from server (${status}).`,
        action: () => window.location.reload(),
        button: 'Continue',
      })
      return
    }

    // Normalize some odd responses to always return FormErrors
    let {errors, message, reason} = data || {
      reason: 'empty',
      message: 'Server returned empty response.',
    }
    if (!errors || isEmptyObj(errors)) {
      reason ||= status ? `${status}` : 'unknown'
      message ||= `Unknown error (${reason})`
    } else if (reason === 'validation' && errors && !isEmptyObj(errors)) {
      message = reason = undefined
    }
    return {errors, message, reason}
  }

  return log.tap({
    ...meta,
    checkoutName,
    setCheckoutName,
    checkout,
    submit,
    loading: checkoutsLoading || checkoutLoading || submitting,
    ...(dialog && {dialog}),
  })
}
