import classNames from 'classnames'
import {useMergedRefs} from 'quickstart/hooks/useMergedRefs'
import {Variant} from 'quickstart/styled-components/theme/base'
import {dataProps, FontKey, Rhythm, typography} from 'quickstart/theme'
import * as Render from 'quickstart/utils/render'
import {
  ComponentProps,
  createContext,
  ElementType,
  JSX,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {logger} from 'tizra'
import S from './styles.module.css'

const log = logger('Text')

const TagName = 'div' satisfies ElementType
type TagName = typeof TagName

export interface TextOptions extends Render.Options {
  bold?: boolean
  // clamp?: boolean
  font?: FontKey
  italic?: boolean
  lines?: number
  prose?: boolean // shortcut sets rhythm and font
  proseFont?: FontKey
  rhythm?: Rhythm
  ui?: never // disused since Prose component
  underline?: boolean
  variant?: Variant
  theme?: any // drop for Linaria/SC compat
}

const HEADING_TAGS = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  sectionHead: 'h2',
  sectionHeadTight: 'h2',
  cardHead: 'div', // TODO: h3?
  headlineHead: 'div',
} as {
  [k in Variant]: keyof JSX.IntrinsicElements
}

const TAGS = HEADING_TAGS

const TextContext = createContext<null | {
  variant: Variant
  ref: RefObject<HTMLElement | null>
}>(null)

const useText = Render.createHook<TagName, TextOptions>(
  ({
    prose = false,
    rhythm = prose ? 'prose' : undefined,
    variant,
    proseFont,
    font,
    bold,
    italic,
    underline,
    lines,
    theme, // remove from props
    ...props
  }) => {
    return Render.mergeProps(
      {
        className: S.text,
        ...dataProps({
          bold,
          clamp: lines,
          font,
          italic,
          proseFont,
          reset: !!variant,
          rhythm,
          underline,
        }),
        style: {
          ...(variant && typography.modVars[variant]),
          '--eg-lines': lines,
        },
      },
      props,
    )
  },
)

export type TextProps<T extends ElementType = TagName> = Render.Props<
  T,
  TextOptions
>

function* ancestors(el: HTMLElement | null) {
  while (el) {
    yield el
    el = el.parentElement
  }
}

export const Text = (props: TextProps) => {
  const {ref: parentRef, variant: parentVariant} = useContext(TextContext) || {}
  const variant = props.variant || parentVariant || 'textMd'
  const variantProseFont = variant in HEADING_TAGS ? 'heading' : 'body'
  const variantFont =
    props.prose || variant in HEADING_TAGS ? variantProseFont : undefined

  // Detect disconnection from parent in DOM tree due to portal.
  const ref = useRef<HTMLElement>(null)
  const [forceVariant, setForceVariant] = useState(false)
  useEffect(() => {
    const {current: child} = ref
    const {current: parent} = parentRef || {}
    if (child && parent && ![...ancestors(child)].includes(parent)) {
      setForceVariant(true)
    }
  }, [ref, parentRef, setForceVariant])

  const textProps = useText({
    proseFont: variantProseFont,
    font: variantFont,
    ...props,
    variant: variant !== parentVariant || forceVariant ? variant : undefined,
    ref: useMergedRefs([ref, props.ref]),
  })

  // Default tag by variant.
  const Type = props.as || TAGS[variant] || TagName

  return (
    <TextContext.Provider value={{variant, ref}}>
      {Render.createElement(Type, textProps)}
    </TextContext.Provider>
  )
}

export const H1 = (props: TextProps) => <Text variant="h1" {...props} />

export const H2 = (props: TextProps) => <Text variant="h2" {...props} />

export const H3 = (props: TextProps) => <Text variant="h3" {...props} />

export const H4 = (props: TextProps) => <Text variant="h4" {...props} />

export const textComponent =
  (config: Omit<TextProps, 'children'> | TextProps['variant']) =>
  (props: TextProps) => (
    <Text
      {...(typeof config === 'string' ? {variant: config} : config)}
      {...props}
    />
  )

interface ProseProps extends ComponentProps<'div'> {
  as?: any
  fonts?: 'prose' | 'ui'
}

export const Prose = ({
  as: asProp = 'div',
  fonts = 'prose',
  ...props
}: ProseProps) => {
  const ProseAs = asProp as 'div'
  return (
    <ProseAs
      data-fonts={fonts}
      {...props}
      className={classNames(S.prose, props.className)}
    />
  )
}
